import React from 'react';
import { Fade } from 'react-slideshow-image';
import Link from 'gatsby-link';

import img1 from '../../../assets/images/NS_patientsite_homepage_slider1.png';
import img2 from '../../../assets/images/NS_patientsite_homepage_slider2.png';
import img3 from '../../../assets/images/NS_patientsite_homepage_slider3.png';

import img1Mobile from '../../../assets/images/NS_patientsite_homepage_slider1_mobile.png';
import img2Mobile from '../../../assets/images/NS_patientsite_homepage_slider2_mobile.png';
import img3Mobile from '../../../assets/images/NS_patientsite_homepage_slider3_mobile.png';

import brochurePDF from '../../../assets/05337-Patient-Ed-Brochure.pdf';

import 'react-slideshow-image/dist/styles.css';
import './index.scss';

const fadeProperties = {
  // duration: 10000,
  // transitionDuration: 500,
  duration: 4000,
  transitionDuration: 400,
  infinite: true,
  indicators: false,
  arrows: false,
  indicators: true,
  onChange: (oldIndex, newIndex) => {
    // console.log(`fade transition from ${oldIndex} to ${newIndex}`);
  },
};

// For customizations
// https://github.com/femioladeji/react-slideshow#readme

const HBCarousel = ({ className, bg, children }) => {
  // const copyBlock = (
  //   <div className='copy-block-container'>
  //         <h1>Patients are <br /> at the heart</h1>
  //         <p><em>of everything we do</em></p>
  //         <p className='small'>"We put breakthrough science within reach"</p>
  //   </div>
  // )

  return (
    <div className="slide-container">
      <Fade {...fadeProperties}>
        <div className="each-fade hb-slide1">
          {/* {copyBlock} */}
          <div className="image-container">
            <Link to="/vitaminb12/about">
              <img src={img1} className="hide-mobile" />
              <img src={img1Mobile} className="hide-desktop" />
            </Link>
          </div>
        </div>
        <div className="each-fade hb-slide2">
          <div className="image-container">
            <Link to="/vitaminb12/b12-deficiency">
              <img src={img2} className="hide-mobile" />
              <img src={img2Mobile} className="hide-desktop" />
            </Link>
          </div>
        </div>
        <div className="each-fade hb-slide3">
          <div className="image-container">
            <a
              target="_blank"
              href="https://nascobal-savings-card.azurewebsites.net/savings-card/"
              rel="noopener noreferrer"
            >
              <img src={img3} className="hide-mobile" />
              <img src={img3Mobile} className="hide-desktop" />
            </a>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default HBCarousel;
