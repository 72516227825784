import React, { useContext, useState, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import ContentBlock from 'components/Content/ContentBlock';
// import ColorBlock from 'components/Content/ColorBlock';
import Carousel from 'components/Carousel';

// const copayTracking = {
//   category: 'Button-Copay',
//   action: 'clicked on Button-Copay',
//   label: 'Download Copay Card',
// };
// const brochureTracking = {
//   category: 'Button-PatientBrochure',
//   action: 'clicked on Button-PatientBrochure',
//   label: 'Download Patient Brochure - Patient',
// };

const IndexPage = props => {
  let Subscription = null;
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialHeight, setInitialHeight] = useState('100vh');

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    setIsLoaded(true);
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
      setInitialHeight('auto');
    };
  }, []);

  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  const metaData = appConfigs?.metaData?.patient;

  // const brandNames = {
  //   NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
  //   BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
  //   NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  // };

  const renderPage = () => {
    return (
      isLoaded && (
        <div id="nascobal-patient-home">
          <ContentBlock id="top">
            <Grid
              fluid
              style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
            >
              <ScrollableAnchor id="vitamin_b12_deficiency">
                <div>
                  <Carousel id="top" />
                  <Row between="xs">
                    <Col xs={12} md={6}>
                      <BrandCTA
                        href="/signup"
                        LinkCaption="SIGN UP FOR RESOURCES AND UPDATES"
                      >
                        SIGN UP FOR UPDATES
                      </BrandCTA>
                    </Col>
                    <Col xs={12} md={6}>
                      <BrandCTA
                        ExternalLink
                        href={'/pdfs/NS-05830_Patient_Brochure.pdf'}
                        LinkCaption="DOWNLOAD PATIENT BROCHURE"
                      >
                        DOWNLOAD PATIENT BROCHURE
                      </BrandCTA>
                    </Col>
                  </Row>
                </div>
              </ScrollableAnchor>
            </Grid>
          </ContentBlock>
          <ScrollableAnchor id="isi_anchor">
            <div />
          </ScrollableAnchor>
        </div>
      )
    );
  };
  const bodyHeight = {
    // height: initialHeight,
  };
  return (
    <Layout indication={indication} className="patient-home">
      <Seo {...metaData} />
      <div className="body-content-container" style={bodyHeight}>
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default IndexPage;
